import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Segment, Header, Button, Table, Icon } from "semantic-ui-react";

import ShareButton from "../ShareButton";
import { calculateScore, calculateGrade, timeConverter } from "../../utils";
import { useParams } from "react-router-dom";

const Stats = ({
  totalQuestions,
  correctAnswers,
  timeTaken,
  replayQuiz,
  resetQuiz,
}) => {
  const [resultQuiz, setResultQuiz] = useState();
  const [loading, setLoading] = useState(false);
  const { stack, secret_key } = useParams();
  useEffect(() => {
    getResult();
  }, []);
  const getResult = () => {
    setLoading(true);
    const user = localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user"))
      : null;
    const GetResultQuiz = `${process.env.REACT_APP_API_URL}/question/result?stack=${stack}&name=${user.name}&secret_key=${secret_key}&email=${user.email}`;
    fetch(GetResultQuiz)
      .then((respone) => respone.json())
      .then((data) => {
        setResultQuiz(data);
        setLoading(false);
      });
  };

  return (
    <Segment>
      <Header as="h1" textAlign="center" block>
        Score: {resultQuiz?.score}
      </Header>
      {/* <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Question</Table.HeaderCell>
            <Table.HeaderCell>Answer</Table.HeaderCell>
            <Table.HeaderCell>result</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {resultQuiz?.data?.map((item, index) => (
            <Table.Row key={index} positive={item?.score > 0}>
              <Table.Cell>
                {item?.question_id || "-"}. {item?.question || ""}
              </Table.Cell>
              <Table.Cell>{item?.answer || "-"}</Table.Cell>
              <Table.Cell>
                {item?.score > 0 ? (
                  <Icon inverted color="green" name="check" />
                ) : (
                  <Icon inverted color="red" name="close" />
                )}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table> */}
      <div style={{ marginTop: 35 }}>
        <Button
          color="teal"
          content="Back to Home"
          onClick={resetQuiz}
          size="big"
          icon="home"
          labelPosition="left"
          style={{ marginBottom: 8 }}
        />
      </div>
    </Segment>
  );
};

Stats.propTypes = {
  totalQuestions: PropTypes.number.isRequired,
  correctAnswers: PropTypes.number.isRequired,
  timeTaken: PropTypes.number.isRequired,
  replayQuiz: PropTypes.func.isRequired,
  resetQuiz: PropTypes.func.isRequired,
};

export default Stats;
