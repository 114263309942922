import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Container,
  Segment,
  Item,
  Dropdown,
  Divider,
  Button,
  Message,
  Form,
} from "semantic-ui-react";

import Offline from "../Offline";
import { useParams } from "react-router-dom";

const Main = ({ startQuiz }) => {
  const [category, setCategory] = useState("0");
  const [numOfQuestions, setNumOfQuestions] = useState(5);
  const [difficulty, setDifficulty] = useState("0");
  const [questionsType, setQuestionsType] = useState("0");
  const { stack, secret_key } = useParams();
  const [countdownTime, setCountdownTime] = useState({
    hours: 0,
    minutes: 120,
    seconds: 0,
  });
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState(null);
  const [offline, setOffline] = useState(false);
  const [valueForm, setValueForm] = useState({
    email: "",
    name: "",
    stack: stack,
  });

  const handleTimeChange = (e, { name, value }) => {
    setCountdownTime({ ...countdownTime, [name]: value });
  };

  let allFieldsSelected = false;
  if (
    category &&
    numOfQuestions &&
    difficulty &&
    questionsType &&
    (countdownTime.hours || countdownTime.minutes || countdownTime.seconds)
  ) {
    allFieldsSelected = true;
  }

  const fetchData = () => {
    setProcessing(true);
    if (error) setError(null);
    const GetQuestions = `${process.env.REACT_APP_API_URL}/question?stack=${
      stack || ""
    }&question_id=1&email=${valueForm.email}&name=${
      valueForm.name
    }&secret_key=${secret_key || ""}`;

    fetch(GetQuestions)
      .then((respone) => respone.json())
      .then((data) =>
        setTimeout(() => {
          setProcessing(false);
          console.log(data);
          if (data?.statusCode) {
            setError(data);
          } else {
            startQuiz(data, 1500);
          }
        }, 1000)
      )
      .catch((error) =>
        setTimeout(() => {
          if (!navigator.onLine) {
            setOffline(true);
          } else {
            setProcessing(false);
            setError(error);
          }
        }, 1000)
      );
  };

  const handleChange = (e, { name, value }) => {
    setValueForm({ ...valueForm, [name]: value });
  };

  const handleSubmit = () => {
    localStorage.setItem(
      "user",
      JSON.stringify({ ...valueForm, stack: stack, secrect: secret_key })
    );
    fetchData();
  };

  return (
    <Container>
      <Segment vertical>
        <Item.Group divided>
          <Item>
            {/* <Item.Image src={mindImg} /> */}
            <Item.Content>
              <Item.Header>
                <h1>The Ultimate Quiz</h1>
              </Item.Header>
              {error && (
                <Message error onDismiss={() => setError(null)}>
                  <Message.Header>Error!</Message.Header>
                  {error.message}
                </Message>
              )}
              <Divider />
              <Form onSubmit={handleSubmit}>
                <Form.Group widths="equal">
                  <Form.Input
                    fluid
                    label="Email"
                    name="email"
                    placeholder="Email"
                    onChange={handleChange}
                    type="email"
                  />
                  <Form.Input
                    fluid
                    label="Name"
                    name="name"
                    placeholder="Name"
                    onChange={handleChange}
                  />
                  {/* <Form.Dropdown
                    fluid
                    selection
                    name="stack"
                    placeholder="Select Stack"
                    label="Stack"
                    options={CATEGORIES}
                    value={valueForm.stack}
                    onChange={handleChange}
                    disabled={processing}
                  /> */}
                </Form.Group>

                <Form.Button content="Submit">Submit</Form.Button>
              </Form>
            </Item.Content>
          </Item>
        </Item.Group>
      </Segment>
      <br />
    </Container>
  );
};

Main.propTypes = {
  startQuiz: PropTypes.func.isRequired,
};

export default Main;
