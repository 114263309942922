import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Container,
  Segment,
  Item,
  Divider,
  Button,
  Icon,
  Message,
  Menu,
  Header,
  Loader,
  Dimmer,
} from "semantic-ui-react";
import he from "he";
import Countdown from "../Countdown";
import { getLetter } from "../../utils";
import { useParams } from "react-router-dom";

const Quiz = ({ dataQuestion, countdownTime, endQuiz }) => {
  const { stack, secret_key } = useParams();
  const [questionIndex, setQuestionIndex] = useState(1);
  const [correctAnswers, setCorrectAnswers] = useState(0);
  const [userSlectedAns, setUserSlectedAns] = useState(null);
  const [questionsAndAnswers, setQuestionsAndAnswers] = useState([]);
  const [timeTaken, setTimeTaken] = useState(null);
  const [data, setData] = useState(dataQuestion);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleItemClick = (e, { name }) => {
    setUserSlectedAns(name);
  };

  async function postData(data = {}) {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/question/submit`,
      {
        method: "POST",
        mode: "cors", //
        cache: "no-cache",
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data), // body data type must match "Content-Type" header
      }
    );
    return response.json(); // parses JSON response into native JavaScript objects
  }

  const handleNext = async () => {
    let point = 0;
    setLoading(true);
    if (error) setError(null);
    const qna = questionsAndAnswers;
    qna.push({
      question: he.decode(data.question),
      user_answer: userSlectedAns,
    });

    const total = data?.total || 10;

    if (questionIndex === total) {
      await sendAnswer();
      return endQuiz({
        totalQuestions: total,
        correctAnswers: correctAnswers + point,
        timeTaken,
        questionsAndAnswers: qna,
      });
    }

    const user = localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user"))
      : null;

    if (user) {
      await sendAnswer();
      const GetQuestions = `${
        process.env.REACT_APP_API_URL
      }/question?stack=${stack}&name=${user.name}&question_id=${
        questionIndex + 1
      }&email=${user.email}&secret_key=${secret_key}`;
      fetch(GetQuestions)
        .then((respone) => respone.json())
        .then((data) => {
          if (data?.statusCode) {
            setError(data);
          } else {
            setData(data);
            setQuestionIndex(questionIndex + 1);
            setUserSlectedAns(null);
            setQuestionsAndAnswers(qna);
            setLoading(false);
          }
        })
        .catch((error) => {
          setError(error);
        });
    }
  };

  const sendAnswer = async () => {
    const user = localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user"))
      : null;

    if (user) {
      await postData({
        stack: stack,
        question_id: data?.id,
        name: user?.name || "",
        email: user?.email || "",
        answer: userSlectedAns || "",
        secret_key: secret_key,
      }).catch((err) => console.log(err));
    }
  };

  const timeOver = async (timeTaken) => {
    const total = data?.total || 10;
    if (questionIndex < total) {
      return handleNext();
    } else {
      await sendAnswer();
      return endQuiz({
        totalQuestions: total || 10,
        correctAnswers,
        timeTaken,
        questionsAndAnswers,
      });
    }
  };

  return (
    <Item.Header>
      <Container>
        {error && (
          <Message error onDismiss={() => setError(null)}>
            <Message.Header>Error!</Message.Header>
            {error.message}
          </Message>
        )}
        <Item.Group divided>
          {!loading ? (
            <Item>
              {data && (
                <Item.Content>
                  <Item.Extra>
                    <Header as="h1" block floated="left">
                      <Icon name="info circle" />
                      <Header.Content>
                        {`Question No.${questionIndex} of ${data?.total || 10}`}
                      </Header.Content>
                    </Header>
                    <Countdown
                      countdownTime={process.env.REACT_APP_TIME_RANGE_QUESTION}
                      timeOver={timeOver}
                      setTimeTaken={setTimeTaken}
                    />
                  </Item.Extra>
                  <br />
                  <Item.Meta>
                    <Message
                      size="huge"
                      floating
                      style={{
                        background: "#ffffff00",
                        backdropFilter: "blur(10px)",
                      }}
                    >
                      {data?.img && (
                        <img
                          src={
                            data?.img
                              ? `${process.env.REACT_APP_IMG_URL}${data?.img}`
                              : ""
                          }
                          className="img-question"
                        />
                      )}
                      <b
                        style={{ fontSize: 18 }}
                        dangerouslySetInnerHTML={{
                          __html: `${`Q. ${data?.question}`}`,
                        }}
                      ></b>
                    </Message>
                    <br />
                    <Item.Description>
                      <h3>Please choose one of the following answers:</h3>
                    </Item.Description>
                    <Divider />
                    <Menu vertical fluid size="massive">
                      {data?.option?.map((option, i) => {
                        const letter = getLetter(i);
                        const decodedOption = he.decode(option);

                        return (
                          <Menu.Item
                            key={decodedOption}
                            name={decodedOption}
                            active={userSlectedAns === decodedOption}
                            onClick={handleItemClick}
                          >
                            <b style={{ marginRight: "8px" }}>{letter}</b>
                            {decodedOption}
                          </Menu.Item>
                        );
                      })}
                    </Menu>
                  </Item.Meta>
                  <Divider />
                  <Item.Extra>
                    <Button
                      primary
                      content="Next"
                      onClick={handleNext}
                      floated="right"
                      size="big"
                      icon="right chevron"
                      labelPosition="right"
                      disabled={!userSlectedAns}
                      className="w-mobile-100"
                    />
                  </Item.Extra>
                </Item.Content>
              )}
            </Item>
          ) : (
            <></>
          )}
        </Item.Group>

        <br />
      </Container>
    </Item.Header>
  );
};

Quiz.propTypes = {
  data: PropTypes.array.isRequired,
  countdownTime: PropTypes.number.isRequired,
  endQuiz: PropTypes.func.isRequired,
};

export default Quiz;
