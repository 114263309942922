import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Container,
  Segment,
  Item,
  Form,
  Message,
  Header,
  Button,
  Icon,
  Label,
  Modal,
  Table,
} from "semantic-ui-react";

import Offline from "../Offline";
import Layout from "../Layout";

const Authen = () => {
  const [valueForm, setValueForm] = useState({
    stack: "",
    password: "",
    quantity: 1,
  });
  const [dataStack, setDataStack] = useState();
  const [dataResult, setDataResult] = useState();
  const [processing, setProcessing] = useState(false);
  const [resPost, setResPost] = useState();
  const [error, setError] = useState(null);
  const [openModalDetail, setOpenModalDetail] = useState({
    isOpen: false,
    stack: "",
    email: "",
    data: null,
  });

  const fetchData = () => {
    setProcessing(true);
    if (error) setError(null);
    const GetQuestions = `${process.env.REACT_APP_API_URL}/common/stack`;

    fetch(GetQuestions)
      .then((respone) => respone.json())
      .then((data) => {
        setProcessing(false);
        console.log(data);
        if (data?.statusCode || data?.statusCode == "409") {
          setError(data);
        } else {
          const dataCooked = data.map((item) => {
            return {
              key: item,
              text: item,
              value: item,
            };
          });
          setValueForm({ ...valueForm, stack: data[0] });
          setDataStack(dataCooked);
        }
      })
      .catch((error) => {
        setProcessing(false);
        setError(error);
      });
  };

  async function handleToggleModal(email, stack) {
    const data = await fetchDataDetail({
      stack: stack,
      email: email,
      password: valueForm?.password || localStorage.getItem("password"),
    });

    setOpenModalDetail({
      isOpen: true,
      stack: stack,
      email: email,
      data: data,
    });
  }

  async function fetchDataDetail(data) {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/auth/result`,
      {
        method: "POST",
        mode: "cors", //
        cache: "no-cache",
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    );
    return response.json();
  }

  const fetchDataResult = () => {
    setProcessing(true);
    if (error) setError(null);
    const GetDataResult = `${process.env.REACT_APP_API_URL}/question/export-result`;

    fetch(GetDataResult)
      .then((respone) => respone.json())
      .then((data) => {
        setProcessing(false);
        console.log(data);
        if (data?.statusCode) {
          setError(data);
        } else {
          setDataResult(data);
        }
      })
      .catch((error) => {
        setProcessing(false);
        setError(error);
      });
  };

  async function postData(data = {}) {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/auth/gen-code`,
      {
        method: "POST",
        mode: "cors", //
        cache: "no-cache",
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    );
    return response.json();
  }

  useEffect(() => {
    fetchData();
    fetchDataResult();
  }, []);

  const handleChange = (e, { name, value }) => {
    setValueForm({ ...valueForm, [name]: value });
  };

  const handleSubmit = async () => {
    localStorage.setItem("password", valueForm?.password);
    const res = await postData(valueForm);
    if (res?.includes("statusCode")) {
      setError(JSON.parse(res));
      setResPost();
    } else {
      setResPost(Array.isArray(res) ? res : [res]);
      setError();
    }
  };

  return (
    <Layout>
      <Container className="auth-page">
        <Item.Header>
          <h1>Generate Link Quiz</h1>
        </Item.Header>
        <Segment>
          {error && (
            <Message error onDismiss={() => setError(null)}>
              <Message.Header>Error!</Message.Header>
              {error.message}
            </Message>
          )}
          <Item.Group divided>
            <Item>
              {/* <Item.Image src={mindImg} /> */}
              <Item.Content>
                <Form onSubmit={handleSubmit}>
                  <Form.Group widths="equal">
                    <Form.Input
                      fluid
                      label="Password"
                      name="password"
                      placeholder="Password"
                      onChange={handleChange}
                    />
                    <Form.Input
                      fluid
                      label="Quantity"
                      name="quantity"
                      placeholder="quantity"
                      type="number"
                      min="1"
                      max="20"
                      onChange={handleChange}
                    />
                    <Form.Dropdown
                      fluid
                      selection
                      name="stack"
                      placeholder="Select Stack"
                      label="Stack"
                      options={dataStack}
                      value={valueForm.stack}
                      onChange={handleChange}
                      disabled={processing}
                    />
                  </Form.Group>

                  <Form.Button content="Submit" color="green">
                    Generate
                  </Form.Button>
                </Form>
              </Item.Content>
            </Item>
          </Item.Group>

          {resPost && resPost?.length > 0 ? (
            <Segment className="url-box">
              {resPost?.map((item, index) => (
                <div key={index}>
                  <Header as="h3" textAlign="left" block>
                    {item}
                  </Header>
                  <Button
                    animated="vertical"
                    disabled={!item}
                    onClick={() => navigator.clipboard.writeText(item)}
                    color="green"
                  >
                    <Button.Content hidden>Copy</Button.Content>
                    <Button.Content visible>
                      <Icon name="copy" />
                    </Button.Content>
                  </Button>
                </div>
              ))}
            </Segment>
          ) : (
            <p>-</p>
          )}

          <h3>Result Quiz</h3>

          {dataResult && (
            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Email</Table.HeaderCell>
                  <Table.HeaderCell>Name</Table.HeaderCell>
                  <Table.HeaderCell>stack</Table.HeaderCell>
                  <Table.HeaderCell>score</Table.HeaderCell>
                  <Table.HeaderCell>Actions</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {dataResult?.map((item) => (
                  <Table.Row key={item?.email} positive={item?.score > 5}>
                    <Table.Cell
                      onClick={() =>
                        handleToggleModal(item?.email, item?.stack)
                      }
                      className="custom-col"
                    >
                      {item?.email || "-"}
                    </Table.Cell>
                    <Table.Cell>{item?.name || "-"}</Table.Cell>
                    <Table.Cell>{item?.stack || "-"}</Table.Cell>
                    <Table.Cell>{item?.score || "-"}</Table.Cell>
                    <Table.Cell
                      className="custom-col"
                      onClick={() =>
                        handleToggleModal(item?.email, item?.stack)
                      }
                    >
                      <Icon
                        name="eye"
                        color="green"
                        style={{ margin: "0 auto" }}
                      />
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
              {/* <Table.Footer>
                <Table.Row>
                  <Table.HeaderCell colSpan="3">
                    <Menu floated="right" pagination>
                      <Menu.Item as="a" icon>
                        <Icon name="chevron left" />
                      </Menu.Item>
                      <Menu.Item as="a">1</Menu.Item>
                      <Menu.Item as="a">2</Menu.Item>
                      <Menu.Item as="a">3</Menu.Item>
                      <Menu.Item as="a">4</Menu.Item>
                      <Menu.Item as="a" icon>
                        <Icon name="chevron right" />
                      </Menu.Item>
                    </Menu>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Footer> */}
            </Table>
          )}
        </Segment>
      </Container>
      <Modal
        closeIcon
        open={openModalDetail.isOpen}
        // trigger={<Button>Show Modal</Button>}
        onClose={() =>
          setOpenModalDetail({
            isOpen: false,
            stack: "",
            email: "",
            data: null,
          })
        }
        onOpen={() => setOpenModalDetail({ ...openModalDetail, isOpen: true })}
      >
        <Header
          icon="archive"
          content={`Result Quiz - ${openModalDetail?.data?.name}`}
        />

        <Modal.Content>
          <Header as="h1" textAlign="center" block>
            Stack: {openModalDetail?.data?.stack} | Score:{" "}
            {openModalDetail?.data?.score}
          </Header>

          {openModalDetail?.data && openModalDetail?.data?.data?.length > 0 ? (
            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Question</Table.HeaderCell>
                  <Table.HeaderCell>Answer</Table.HeaderCell>
                  <Table.HeaderCell>result</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {openModalDetail?.data?.data?.map((item, index) => (
                  <Table.Row key={index} positive={item?.score > 0}>
                    <Table.Cell>
                      {item?.question_id || "-"}. {item?.question || ""}
                    </Table.Cell>
                    <Table.Cell>{item?.answer || "-"}</Table.Cell>
                    <Table.Cell>
                      {item?.score > 0 ? (
                        <Icon inverted color="green" name="check" />
                      ) : (
                        <Icon inverted color="red" name="close" />
                      )}
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          ) : (
            ""
          )}
        </Modal.Content>
      </Modal>
    </Layout>
  );
};

export default Authen;
