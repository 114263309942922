import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./components/App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Link,
} from "react-router-dom";
import Authen from "./components/Authen";

const router = createBrowserRouter([
  {
    path: "/quiz/:stack/:secret_key",
    element: <App />,
  },
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/authen",
    element: <Authen />,
  },
]);

if (process.env.NODE_ENV === "production") {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
}

ReactDOM.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
  document.getElementById("root")
);

serviceWorkerRegistration.register();
